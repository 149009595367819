import cn from "classnames";

import * as styles from "./BackgroundShape.module.scss";

interface BackgroundShapeProps {
  adoptionClassName?: string;
  variantName?: string;
}

const BackgroundShape = ({ variantName, adoptionClassName }: BackgroundShapeProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="var(--background-shape-fill, var(--b-200-color))"
    viewBox="0 0 1274 718"
    className={cn("at-background-shape", styles.backgroundShape, adoptionClassName, {
      [styles[variantName as string]]: variantName,
    })}
    aria-hidden="true"
    focusable="false"
    tabIndex={-1}
  >
    <path d="M6 461C-1 361-20 102 93 45c112-56 471-63 665 0 195 63 348-55 358 118 10 172 215 80 141 309-74 230-200 300-423 203s-424 32-567 12S14 586 6 461Z" />
  </svg>
);

export default BackgroundShape;
