import {
  countries,
  CountryCode,
  CountrySubsetConfiguration,
  Locale,
  ZipCodeValidation,
} from "../settings/countries";
import { PageId } from "../settings/pages";
import { ENABLED, getExperimentVariant } from "./experiments";

export const isIdDocumentRequired = (country: CountryCode): boolean => {
  return countries[country].idDocumentRequired;
};

export const getLocaleByCountry = (country?: CountryCode): Locale => {
  if (!country) {
    return "es";
  }

  return countries[country].defaultLocale;
};

export const getCountryByLocale = (locale?: Locale): CountryCode => {
  if (!locale || !Object.values(CountryCode).includes(locale.toUpperCase() as CountryCode)) {
    return CountryCode.ES;
  }

  return locale.toUpperCase() as CountryCode;
};

export const homePath = (country?: CountryCode): string => {
  if (!country) {
    return "/es/";
  }

  return `/${country.toLowerCase()}`;
};

export const shouldListTaxes = (country: CountryCode): boolean => {
  return countries[country].listTaxes;
};

export const shouldShowAlternativeDisclaimer = (country: CountryCode): boolean => {
  return countries[country].alternativeDisclaimer;
};

export const getPhoneNumberLength = (country: CountryCode): Record<string, number> => {
  return countries[country].phoneNumberLength;
};

export const getPhoneNumberPrefix = (country: CountryCode): string => {
  return countries[country].phoneNumberPrefix;
};

export const shouldShowContactsLinks = (country: CountryCode): boolean => {
  return countries[country].contactLinks;
};

export const getMultiplePetsDiscount = (country: CountryCode): number => {
  return countries[country].multiplePetsDiscount;
};

export const shouldShowHasTattooAnswer = (country: CountryCode): boolean => {
  return countries[country].hasTattooAnswer;
};

export const shouldShowComplianceErrorMessage = (country: CountryCode): boolean => {
  return countries[country].hasComplianceErrorMessage;
};

export const shouldNavigateToSmallPrintPages = (country: CountryCode): boolean => {
  return countries[country].smallPrintPages;
};

export const shouldShowEuFederLogos = (country: string): boolean => {
  if (!country) {
    return false;
  }

  return countries[country as CountryCode].euFederLogos;
};

export const getZipCodeLength = (country: CountryCode): ZipCodeValidation => {
  return countries[country].zipCode;
};

export const shouldShowAlternativeOnboardingEmailTitle = (country: CountryCode): boolean => {
  return countries[country].alternativeOnboardingEmailTitle;
};

export const shouldAllowBecasDiscount = (country: CountryCode): boolean => {
  return countries[country].becasDiscount;
};

export const getCountriesSubset = (): CountrySubsetConfiguration[] =>
  Object.values(countries).map(({ name, countryCode, defaultLocale }) => ({
    name,
    countryCode,
    defaultLocale,
  }));

export const isPolicyStartDateChangeAvailable = (country: CountryCode): boolean =>
  countries[country].allowChangePolicyStartDate;

export const shouldAllowFakeChipNumber = (country: CountryCode): boolean =>
  countries[country].fakeChipNumber;

export const shouldShowChatLink = (country: CountryCode, pageId: PageId): boolean =>
  countries[country].chatLink && countries[country].pagesWithChatLink.includes(pageId);

export const shouldShowAlternativeHeroBulletPoints = (country: CountryCode): boolean =>
  countries[country].alternativeHeroBulletPoints;

export const shouldAddOtherInGenderOptions = (country: CountryCode): boolean =>
  countries[country].addOtherInGenderOptions;

export const shouldNotifyPreContract = (country: CountryCode): boolean => {
  return countries[country].preContractNotificationIsRequired;
};

export const shouldShowDescriptionInAccessPage = (country: CountryCode): boolean =>
  countries[country].hasDescriptionInAccessPage;

export const shouldAllowCustomCheckoutSession = (): boolean =>
  process.env.GATSBY_CUSTOM_CHECKOUT_PAGE === "true";

export const getPaymentMethodsAvailables = (country: CountryCode): string[] =>
  countries[country].paymentMethods;

export const shouldShowGlAddon = (country: CountryCode): boolean => countries[country].glAddon;

export const shouldShowHowKnowUsQuestion = (country: CountryCode): boolean =>
  countries[country].howKnowUsQuestion;

export const shouldShowAlternativeHealthProductSelector = (country: CountryCode): boolean =>
  countries[country].alternativeHealthProductSelector;

export const getBarkibuCardABTestWeight = (country: CountryCode): [number, number] =>
  countries[country].barkibuCardABTestWeight;

export const shouldShowBarkibuCardSteps = (): boolean => {
  return getExperimentVariant("Barkibu Card on post quotes") === ENABLED;
};

export const shouldShowPhoneNumberInPreQuotes = (country: CountryCode): boolean =>
  countries[country].phoneNumberInPreQuotes;

export const shouldShowScheduleCallOption = (country: CountryCode): boolean =>
  countries[country].scheduleCallOption;
